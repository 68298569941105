import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import Select from "react-select";
import { PopupHeader } from "../components/Headings";
import { HStack, VStack, Error } from "../components/utils";
import {
  Input,
  Label,
  Required,
  TextArea,
} from "../components/shared/InputField";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { useAddDirectorDetails } from "../queries/directorDetails";
import {
  attendedDirectors,
  BoardMeetingModel,
  boardMeetingSchema,
  SelectOptionType,
} from "../types/BoardMeetingsModels";
import { useAddBoardMeetingDetails } from "../queries/boardMeetingDetails";
import { queryClient } from "../queries";

export const AddEditMeetings = ({
  meetings,
  directorData,
  onSecondaryAction = () => {},
  mode,
}: any) => {
  const { mutate: addBoardMeetingMutation } = useAddBoardMeetingDetails();

  const [loading, setLoading] = useState(false);
  // const [directorError, setDirectorError] = useState<string>();
  const [values, setValues] = useState<any>([]);

  const directorList: SelectOptionType[] = directorData?.map((item: any) => ({
    label: item.fullName,
    value: item.id,
  }));

  const boardMeetingInitData: BoardMeetingModel = meetings
    ? {
        name: meetings.name || "",
        dateOfMeeting: meetings.dateOfMeeting || "",
        noOfDirectorsAttended: meetings.noOfDirectorsAttended || "",
        directorsAttended: meetings.directorsAttended.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
        notes: meetings.notes || "",
        tags: meetings.tags || [],
      }
    : {
        name: "",
        dateOfMeeting: "",
        noOfDirectorsAttended: 0,
        directorsAttended: [],
        notes: "",
        tags: [],
      };

  useEffect(() => {
    if (meetings?.directorsAttended && meetings?.directorsAttended.length > 0) {
      setValues(
        meetings.directorsAttended.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [meetings?.directorsAttended]);

  const formik = useFormik<BoardMeetingModel>({
    initialValues: boardMeetingInitData,
    validationSchema: boardMeetingSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (data) => {
      setLoading(true);
      const directorsAttend: attendedDirectors[] =
        data.directorsAttended?.map(
          (item: any) =>
            ({
              name: item.label,
              id: item.value,
            } as attendedDirectors)
        ) || ([] as attendedDirectors[]);

      const meetingDetails: BoardMeetingModel = {
        ...data,
        tags: data.tags?.map((tag: string) => tag.trim()) || [],
        directorsAttended: directorsAttend,
      };

      if (meetings?.id) {
        meetingDetails.id = meetings.id;
      }

      addBoardMeetingMutation(meetingDetails, {
        onSuccess: () => {
          queryClient.invalidateQueries("getBoardMeetingDetails");
          toast("Meeting Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          handleClose();
          setLoading(false);
        },
        onError: (err: any) => {
          toast(err, {
            type: "error",
            autoClose: 2000,
          });
          setLoading(false);
        },
      });
    },
  });

  // useEffect(() => {
  //   const attendedCount = Number(formik.values.noOfDirectorsAttended);
  //   const directorsCount = formik.values.directorsAttended?.length || 0;

  //   if (attendedCount !== directorsCount) {
  //     setDirectorError(
  //       "Number of directors attended does not match the names provided"
  //     );
  //   } else {
  //     setDirectorError(undefined);
  //   }
  // }, [formik.values.noOfDirectorsAttended, formik.values.directorsAttended]);

  const handleClose = () => {
    onSecondaryAction();
  };

  return (
    <>
      <div className="transition ease-in duration-2000 opacity-100 enter:opacity-0">
        <PopupHeader
          text={
            mode !== "Edit" ? "Add Meeting Details" : "Edit Meeting Details"
          }
          onClick={() => {
            handleClose();
          }}
        />
        {/* <form onSubmit={formik.handleSubmit}> */}
        <VStack className="w-full px-10 py-7 gap-9">
          <VStack className="w-full gap-9">
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Meeting Name <Required />
                </Label>
                <Input
                  type="text"
                  placeholder="Enter Name of Meeting"
                  className="w-96"
                  {...formik.getFieldProps(`name`)}
                />
                {formik.errors?.name && <Error text={formik.errors?.name} />}
              </div>

              <div className="flex-1">
                <Label className="text-sm font-normal required">
                  Date of Meeting <Required />
                </Label>
                <Input
                  type="date"
                  className="w-96"
                  max={new Date().toISOString().split("T")[0]}
                  {...formik.getFieldProps(`dateOfMeeting`)}
                />
                {formik.errors?.dateOfMeeting && (
                  <Error text={formik.errors?.dateOfMeeting} />
                )}
              </div>
            </HStack>

            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  No. of Directors attended <Required />
                </Label>
                <Input
                  type="number"
                  placeholder="Enter the no. of Directors attended"
                  className="w-96"
                  {...formik.getFieldProps(`noOfDirectorsAttended`)}
                />
                {formik.errors?.noOfDirectorsAttended && (
                  <Error text={formik.errors?.noOfDirectorsAttended} />
                )}
              </div>

              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Director Name
                </Label>

                <div className="mt-2">
                  <Select
                    className="form-control-solid"
                    options={directorList}
                    placeholder={"Select ..."}
                    value={values}
                    isMulti={true}
                    classNamePrefix="select2-selection"
                    onChange={(e: any) => {
                      setValues(e);
                      formik.setFieldValue(`directorsAttended`, e);
                    }}
                  />
                </div>

                {/* {directorError && <Error text={directorError} />} */}
              </div>
            </HStack>

            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Tags
                  <span className="text-xxs text-gray-400">{` (Comma separated)`}</span>
                </Label>
                <Input
                  type="text"
                  placeholder="Eg: Tag1, Tag2, Tag3"
                  value={formik.values.tags}
                  className="w-96"
                  onChange={(e: any) => {
                    const tags: string[] = e.target.value
                      .split(",")
                      .map((value: string) => value);
                    formik.setFieldValue(`tags`, tags);
                  }}
                />
              </div>
            </HStack>

            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">Notes</Label>
                <TextArea {...formik.getFieldProps(`notes`)} />
              </div>
            </HStack>

            <HStack className="justify-between h-8">
              <SecondaryCTAButton
                event-name="Cancel Meeting Details"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Save Meeting Details"
                type="submit"
                buttonSize={ButtonSize.SMALL}
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                loading={loading}
                disabled={loading}
              >
                <Icon
                  icon="lsicon:save-filled"
                  width={18}
                  height={18}
                  className={`mr-1 border-transparent text-white bg-transparent rounded-md`}
                />
                Save
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        </VStack>
        {/* </form> */}
      </div>
    </>
  );
};
